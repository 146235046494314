
import { Options, Vue } from 'vue-class-component'
import { toDate } from '../utils/common'
import { Dialog, Toast } from 'vant'
@Options({
  components: {}
})
export default class Home extends Vue {
  toDate = toDate;

  downbh () {
    Dialog.alert({
      title: '温馨提示',
      message: '取消成功'
    })
  }
}
