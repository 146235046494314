import dayjs from 'dayjs'
/*
 * 日期转换
 * 2020年4月17日
 * 周祥
 */
export const toDate = (value: string | number, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!value) {
    return '-'
  }
  return dayjs(value).format(format)
}
